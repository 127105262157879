import React from "react"

import Layout from "../components/layout"
import ContactusPage from "../components/contactus/index"
import Seo from "../components/seo"

const contactusPage = () => (
  <Layout>
    <Seo title="Contact us - Mass Media Creatives" desc="" keywords="" />
    <ContactusPage />
  </Layout>
)

export default contactusPage
