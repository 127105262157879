import React from "react"
import Slider from "./slider"
import Contact from "./contact"
import Services from "../home/services"

const contact = () => {
  return (
    <>
      <Slider />
      <Contact />
      <Services />
    </>
  )
}

export default contact
