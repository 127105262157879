import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faInstagram,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons"

const contactpage = () => {
  return (
    <section>
      <div className="container py-4" id="contactus">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <h4 className="heading fw-bold  about-heading">Contact us</h4>
            <p className="subheading py-4">
              We believe in nurturing creativity and technology to portray
              powerful brand stories and we strive towards making real
              connections with people. Appreciation from our clients is music to our ears and our biggest asset.
            </p>
            <p className="about-digital py-4">
              Artistic and engaging graphic designs with a touch of empathy
            </p>
          </div>

          <div className="col-12 col-lg-6 bg-white  rounded-left mb-3 mb-lg-0">
            <div className="appointment-forms  py-4">
              <p className="company-name">
                MASS MEDIA <br />
                CREATIVES PVT. LTD
              </p>

              <h4 className="branch-location">Chennai</h4>
              <p className="branch-details">Head Office</p>
              <p>
              #93,1st Floor,PeriyarPathai,Choolaimedu,Chennai-600094. <br />Landline Number : 044 - 23611988 / 044 - 42810608.
                <br /> Mail id : massmediacreatives@gmail.com, 
                <br />info@massmediacreatives.com.
              </p>
              <p className="branch-location">Bangalore</p>
              <p className="branch-details">Branch Office</p>
              <p>
              #69,9th Main Road, 1st Cross, Begur Main Road,
                <br />
                Bommanahalli,Bengaluru - 560068.
                <br />Landline Number : 080 - 41470722,<br />Mobile Number : +91 98848 66818. 
              </p>
              <div className="social-linked mt-3 py-4">
                <span className="footer-follow">Follow on :</span>{" "}
                <a
                  href="https://www.facebook.com/massmediacreatives"
                  className="facebook socials-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookF} size="1x" />
                </a>
                <a
                  href="https://www.instagram.com/massmediacreatives/"
                  className="instagram socials-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} size="1x" />
                </a>
                <a
                  href="/"
                  className="twitter socials-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} size="1x" />
                </a>
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-lg-6  py-4">
            <p>
              You can talk to us about features, trials, pricing or anything
              else!
            </p>
            <p className="contact-team">
              Our team is here to answer all your questions !
            </p>
            <p>
              Send your inquiry through mail or online contact form and one of
              our staff members will get in touch with you within 24 hours.
            </p>

            <div className=" col-lg-12 form-group mb-3">
              <input
                type="text"
                name="name"
                className="form-control"
                id="formGroupExampleInput"
                placeholder="*Name"
                data-rule="minlen:4"
                data-msg="Please enter at least 4 chars"
              />
              <div className="validate"></div>
            </div>
            <div className="col-lg-12 form-group mb-3">
              <input
                type="phone"
                name="number"
                className="form-control"
                id="number"
                placeholder="*Phone No"
                data-rule="minlen:4"
                data-msg="Please enter at least 10 number"
              />
              <div className="validate"></div>
            </div>
            <div className="col-lg-12 form-group mb-3 mt-3 mt-md-0">
              <input
                type="email"
                className="form-control"
                name="email"
                id="email"
                placeholder="*Email Address"
                data-rule="email"
                data-msg="Please enter a valid email"
              />
              <div className="validate"></div>
            </div>

            <div className="col-lg-12 form-group mb-3 mt-3 mt-md-0">
              <textarea
                id="message"
                rows="7"
                placeholder="Massage"
                className="form-control"
                required
                data-error="Write your message"
              ></textarea>
              <div className="validate"></div>
            </div>

            <div className="col-lg-12 form-group mt-3 mt-md-0">
              <button
                type="button"
                className="col-lg-12 btn btn-primary enquiry-submit"
              >
                SUBMIT
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default contactpage
